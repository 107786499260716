import {
  DELETE_SHARED_PRODUCT,
  DELIVERY_DATA,
  GET_ALL_USER_ORDERS,
  GET_ORDER_DETAILS,
  GET_USER_SHARED_PRODUCTS,
  ORDERING,
  ORDER_PRODUCT,
} from "../../utils/constants";

export const setOrderProduct = (payload: Record<string, any>) => ({
  type: ORDER_PRODUCT,
  payload,
});

export const getDeliveryLocations = () => ({
  type: DELIVERY_DATA,
});

export const orderingProduct = (payload: Record<string, any>) => ({
  type: ORDERING,
  payload,
});

export const getUserOrders = (payload: Record<string, any>) => ({
  type: GET_ALL_USER_ORDERS,
  payload,
});

export const getOrderDetails = (payload: any) => ({
  type: GET_ORDER_DETAILS,
  payload,
});

export const getUserSharedProducts = (payload: Record<string, any>) => ({
  type: GET_USER_SHARED_PRODUCTS,
  payload,
});

export const deleteSharedProduct = (payload: number) => ({
  type: DELETE_SHARED_PRODUCT,
  payload,
});
