import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import BottomTabNavigation from "./BottomNavigation";
import { useLocation } from "react-router-dom";
import CartBottomCard from "../../pages/Cart/components/CartBottomCart";
import { useMediaQuery } from "react-responsive";

const ScreenWrapper = ({
  title,
  showBackBtn,
  searchFunction,
  children,
  backFunction,
  clearSearchFunction,
  data = [],
}) => {
  const locations = useLocation();

  const currentPath = locations.pathname.replace("/", "") === "cart";
  const isCustomer = locations.pathname.replace("/cart/", "") === "customer";
  const isCheckout = locations.pathname.replace("/cart/", "") === "checkout";
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <div className="h-screen flex-1 flex flex-col w-screen overflow-x-hidden relative bg-white">
      {/* Header component */}
      <Header
        goBack={backFunction}
        search={searchFunction}
        title={title}
        showBack={showBackBtn}
        onSearchClearFn={clearSearchFunction}
        data={data}
      />
      <div className="min-h-full lg:py-8 py-10 overflow-y-scroll z-0 relative">
        {children}
      </div>
      {currentPath ? (
        <CartBottomCard />
      ) : isCustomer || isCheckout || !isTabletOrMobile ? null : (
        <BottomTabNavigation />
      )}
    </div>
  );
};

ScreenWrapper.defaultProps = {};

ScreenWrapper.propTypes = {
  title: PropTypes.string,
  showBackBtn: PropTypes.bool,
  searchFunction: PropTypes.func,
  children: PropTypes.node.isRequired,
  backFunction: PropTypes.func,
  clearSearchFunction: PropTypes.func,
  data: PropTypes.array,
};

export default ScreenWrapper;
