import React from "react";
import { Link } from "react-router-dom";
import { Product } from "../graphql/types";
import { useAppDispatch } from "../redux/hook";
import { setRecentSearch } from "../redux/slice/product.slice";
import ImageWithLoading from "./common/ImageWithLoad";

interface Props {
  item: Product;
}

const SearchItem = ({ item }: Props) => {
  const dispatch = useAppDispatch();

  const selectProduct = (id: string) => {
    dispatch(setRecentSearch(id));
  };

  return (
    <Link to={`/product/${item?.id}`} onClick={() => selectProduct(item.id)}>
      <div className="mx-2 flex items-center">
        <div className="flex-shrink-0 pt-0.5 h-12 w-12 rounded-md">
          <ImageWithLoading src={item.avatar} />
        </div>
        <div className="ml-3 flex flex-col w-full">
          <span className="text-sm font-medium text-gray-900">
            {item?.title}
          </span>
          <span className="mt-1 font-semibold text-sm text-gray-500">
            {item?.price}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default SearchItem;
