import {
  ADD_CUSTOMER_TO_CART,
  ADD_DELIVERY_TO_CART,
  ADD_PRODUCT_TO_CART,
  ADD_RESELLER_TO_CART,
  CHECKOUT_CART,
  CLEAR_CART,
  EDIT_CART_ITEM,
  GET_COUPON,
  GET_CUSTOMERS,
  REMOVE_PRODUCT_FROM_CART,
} from "../../utils/constants";
import {
  ICartProduct,
  ICustomer,
  IDelivery,
  IReseller,
} from "../../utils/types";

export const addProductToCart = (product: ICartProduct) => ({
  type: ADD_PRODUCT_TO_CART,
  payload: product,
});

export const removeProductFromCart = (product: ICartProduct) => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: product,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const addCustomerToCart = (customer: ICustomer) => ({
  type: ADD_CUSTOMER_TO_CART,
  payload: customer,
});

export const addDeliveryToCart = (delivery: IDelivery) => ({
  type: ADD_DELIVERY_TO_CART,
  payload: delivery,
});

export const addResellerToCart = (reseller: IReseller) => ({
  type: ADD_RESELLER_TO_CART,
  payload: reseller,
});

export const checkoutCart = () => ({
  type: CHECKOUT_CART,
});

export const editCartItem = (product: ICartProduct) => ({
  type: EDIT_CART_ITEM,
  payload: product,
});

export const getResellerCustomers = (resellerId: string) => ({
  type: GET_CUSTOMERS,
  payload: resellerId,
});

export const getPromoCode = (code: string) => ({
  type: GET_COUPON,
  payload: code,
});
