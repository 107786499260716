export const initFreshChat = (others: Record<string, any> = {}) => {
  const { fcWidget } = window as any;
  if (fcWidget) {
    fcWidget.init({
      token: "d6ac32c3-6201-43ea-8d40-3c44c331ca58",
      host: "https://wchat.freshchat.com",
      ...others,
    });
  }
};
