import React from "react";
import EmptyImage from "../../assets/images/emptyImage.jpg";

const find = "tendo-images.s3.amazonaws.com";
const newStr = "d3ug0vbiixnxyq.cloudfront.net";

const ImageWithLoading = ({ src }) => {
  // const loadedImages = {};
  const [loaded, setLoaded] = React.useState(false);
  const imgRef = React.useRef(null);

  const onLoad = () => {
    setLoaded(true);
  };

  const imgSrc = React.useMemo(
    () => (src?.includes(find) ? src.replace(find, newStr) : src),
    [src]
  );

  React.useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      onLoad();
    }
  }, []);

  return (
    <>
      {!loaded && (
        <img
          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
          src={EmptyImage}
          alt="product"
        />
      )}
      <img
        className="h-full w-full object-cover object-center sm:h-full sm:w-full"
        src={imgSrc}
        alt="product"
        onLoad={onLoad}
        ref={imgRef}
        // loading="lazy"
      />
    </>
  );
};

export default ImageWithLoading;
