import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  active?: boolean;
  icon: any;
  title: string;
  index: number;
  path: string;
}

const NavItem = ({ active, icon, title, index, path }: Props) => {
  return (
    <NavLink
      to={path}
      className="relative flex justify-end flex-col items-center flex-grow flex-shrink-0 p-0 m-0 text-center text-xs font-medium"
      style={({ isActive }) => {
        return {
          height: 49,
          color: isActive ? "rgb(33, 150, 243)" : "rgb(108, 108, 108)",
        };
      }}
    >
      <div className="flex flex-col justify-center items-center text-center pt-2">
        <div>{icon}</div>
        <div className="w-full text-xs overflow-ellipsis whitespace-nowrap">
          {title}
        </div>
      </div>
    </NavLink>
  );
};

export default NavItem;
