import * as Types from "../../graphql/types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type AccountRegisterMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AccountRegisterInput>;
}>;

export type AccountRegisterMutation = {
  __typename?: "Mutation";
  accountRegister: {
    __typename?: "Auth";
    token: string;
    user: {
      __typename?: "User";
      country: string;
      email: string;
      facebook_id?: string | null;
      google_id?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      twitter_id?: string | null;
      user_type?: Types.UserAccountType | null;
      username?: string | null;
      verified?: boolean | null;
      paymentDetails?: {
        __typename?: "UserPayment";
        account_name?: string | null;
        account_number?: string | null;
        id?: string | null;
        method?: string | null;
        provider?: string | null;
        user_id?: string | null;
      } | null;
      details?: {
        __typename?: "UserDetails";
        dob?: any | null;
        gender?: string | null;
        id?: string | null;
        referred_by?: string | null;
        user_id?: string | null;
      } | null;
    };
  };
};

export const AccountRegisterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AccountRegister" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "accountRegisterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "accountRegister" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "facebook_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "google_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "account_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "account_number" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "method" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "provider" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_id" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "twitter_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "verified" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "details" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dob" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gender" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "referred_by" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountRegisterMutation,
  AccountRegisterMutationVariables
>;
