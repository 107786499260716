import { combineReducers } from "redux";
import appSlice from "./app.slice";
import authSlice from "./auth.slice";
import orderSlice from "./order.slice";
import productSlice from "./product.slice";
import walletSlice from "./wallet.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import cartSlice from "./cart.slice";

// products reduce persist config
const persistProductConfig = {
  key: "@tendo-web-product",
  storage,
  blacklist: [
    "feedProducts",
    "exploreProducts",
    "categoryProducts",
    "searchProduct",
    "promoProducts",
    "sharedProductPrice",
    "fetchingProductsStatus",
    "isProductFetching",
  ],
};

// app slice persist config
const persistAppConfig = {
  key: "@tendo-web-app",
  storage,
  blacklist: [
    "totalRecord",
    "promoName",
    "productName",
    "isFetching",
    "previousPage",
  ],
};

// orders slice persist config
const persistOrderConfig = {
  key: "@tendo-web-order",
  storage,
  blacklist: ["orders", "sharedProducts", "navigate", "productOrdered"],
};

const rootSlice = combineReducers({
  app: persistReducer(persistAppConfig, appSlice),
  products: persistReducer(persistProductConfig, productSlice),
  auth: authSlice,
  orders: persistReducer(persistOrderConfig, orderSlice),
  wallet: walletSlice,
  cart: cartSlice,
});

export default rootSlice;
