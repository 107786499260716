import { setToggleLogin } from "./../actions/appActions";
import { call, put } from "redux-saga/effects";
import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT,
  REGISTER_USER,
  RESELLER_TOKEN,
  UPDATE_USER_PAYMENT,
  UPDATE_USER_PROFILE,
} from "../../utils/constants";
import {
  setToggleErrorModal,
  setToggleRegister,
  setToggleSignOut,
} from "../slice/app.slice";
import {
  setAuthStatus,
  setIsAuthenticated,
  setUser,
} from "../slice/auth.slice";
import sheetService from "../../services/sheet.service";
import { apiGatewayClient } from "../../utils/apollo-client";
import {
  VerifyAuthQuery,
  VerifyAuthQueryVariables,
  VerifyAuthDocument,
} from "./__generated__/verifyAuth";
import { ApolloQueryResult } from "@apollo/client";

function* handleError(error?: any) {
  const obj: Record<string, any> = {};
  obj.success = false;
  obj.error = error.message || "Something went wrong";
  obj.isError = true;

  yield put(setAuthStatus(obj));
  yield put(setIsAuthenticated(false));
}

function* initRequest() {
  yield put(setIsAuthenticated(true));
  yield put(setAuthStatus(null));
}

function* requestDone() {
  yield put(setIsAuthenticated(false));
  yield put(setAuthStatus(null));
}

const verifyAuth = async (payload: string) => {
  return apiGatewayClient.query<VerifyAuthQuery, VerifyAuthQueryVariables>({
    query: VerifyAuthDocument,
    variables: {
      input: {
        token: payload,
      },
    },
  });
};

function* authSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case AUTHENTICATE_USER:
      try {
        yield initRequest();

        const result: ApolloQueryResult<VerifyAuthQuery> = yield call(
          // sheetService.getUser,
          verifyAuth,
          payload
        );
        if (result.data) {
          const { data } = result;
          const { verifyAuth } = data;
          const { user, token } = verifyAuth;
          if (user) {
            yield put(setUser(user));
            yield localStorage.setItem(RESELLER_TOKEN, token);
            yield requestDone();
          } else {
            yield put(setUser(null));
            yield handleError({ message: "User not found" });
            yield put(setToggleErrorModal(true));
          }
        } else {
          yield handleError({ message: result.data });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data);
        yield put(setToggleErrorModal(true));
      }
      break;
    case LOGIN_USER:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          sheetService.login,
          payload
        );
        // console.log(result);

        if (result.success === true) {
          localStorage.setItem(RESELLER_TOKEN, result?.data?.token);
          yield put(setUser(result?.data));
          yield put(setToggleLogin(false));
          yield requestDone();
        } else {
          yield put(setUser(null));
          yield handleError({ message: result.data });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data);
        yield put(setToggleErrorModal(true));
      }
      break;
    case REGISTER_USER:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          sheetService.createAccount,
          payload
        );
        // console.log(result);

        if (result.success === true) {
          yield put(setUser(result?.data));
          localStorage.setItem(RESELLER_TOKEN, result?.data?.token);
          yield put(setToggleRegister(false));
          yield requestDone();
        } else {
          yield put(setUser(null));
          yield handleError({ message: result.message });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data);
        yield put(setToggleErrorModal(true));
      }
      break;
    case LOGOUT:
      yield put(setUser(null));
      yield put(setToggleSignOut(false));
      localStorage.removeItem(RESELLER_TOKEN);
      break;
    case UPDATE_USER_PROFILE:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          sheetService.updateUser,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success === true) {
          yield put(setUser(result?.data));
          yield put(setIsAuthenticated(false));
          yield put(
            setAuthStatus({ success: true, error: null, isError: false })
          );
        } else {
          yield handleError({ message: result.data });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data);
        yield put(setToggleErrorModal(true));
      }
      break;
    case UPDATE_USER_PAYMENT:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          sheetService.updateUser,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success === true) {
          yield put(setUser(result?.data));
          yield put(setIsAuthenticated(false));
          const obj = { success: true, error: null, isError: false };
          yield put(setAuthStatus(obj));
        } else {
          yield handleError({ message: result.data });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data);
        yield put(setToggleErrorModal(true));
      }
      break;
    default:
      yield console.log("Auth default");
      break;
  }
}

export default authSaga;
