import React, { useEffect, useRef /*useState*/ } from "react";
import { useNavigate } from "react-router-dom";
import { getDeliveryLocations } from "../../../redux/actions/orderActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";

type Props = {};

const CartBottomCard = (props: Props) => {
  const { cart } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const init = useRef({ dispatch });
  const navigate = useNavigate();

  useEffect(() => {
    const { dispatch } = init.current;
    dispatch(getDeliveryLocations());
  }, []);

  const onCheckout = () => {
    navigate("/cart/customer");
  };

  return (
    <div
      className="rounded-t-md p-2 flex flex-col fixed left-0 bottom-0 inset-x-0 bg-white w-screen lg:max-w-7xl lg:mx-auto"
      style={{ marginBottom: "env(safe-area-inset-bottom)" }}
    >
      <div className="flex flex-col my-2">
        <div className="flex justify-between items-center">
          <span className="text-gray-500 text-sm">Total profit added</span>
          <span className=" text-sm font-bold text-gray-800">
            GHS {cart?.totalResellerProfit ?? 0}
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="text-gray- font-medium text-base">
            Total amount to collect
          </span>
          <span className="text-base font-bold text-green-800">
            GHS {cart?.totalAmountToCollect ?? 0}
          </span>
        </div>
      </div>

      <div className="flex my-3 w-full">
        <button
          type="button"
          className={`w-full flex justify-center py-3 px-4 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            !cart ? "bg-gray-300 text-gray-800" : "bg-tendo-active text-white"
          }`}
          onClick={onCheckout}
          disabled={!cart}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default CartBottomCard;
