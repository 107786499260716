import {
  CLOSE_ERROR_MODAL,
  SET_CATEGORY_NAME,
  SET_PROMO_NAME,
  SET_SIGNOUT_MODAL,
  SET_UPDATE_PAYMENT_MODAL,
  SET_UPDATE_PROFILE_MODAL,
  TOGGLE_CART_MODAL,
  TOGGLE_LOGIN,
  TOGGLE_REGISTER,
} from "../../utils/constants";

export const setCategoryName = (payload: string) => ({
  type: SET_CATEGORY_NAME,
  payload,
});

export const setPromoName = (payload: string) => ({
  type: SET_PROMO_NAME,
  payload,
});

export const setToggleLogin = (payload: boolean) => ({
  type: TOGGLE_LOGIN,
  payload,
});

export const setToggleRegister = (payload: boolean) => ({
  type: TOGGLE_REGISTER,
  payload,
});

export const setSignOutModal = (payload: boolean) => ({
  type: SET_SIGNOUT_MODAL,
  payload,
});

export const setUpdateProfileModal = (payload: boolean) => ({
  type: SET_UPDATE_PROFILE_MODAL,
  payload,
});

export const setUpdatePaymentModal = (payload: boolean) => ({
  type: SET_UPDATE_PAYMENT_MODAL,
  payload,
});

export const errorModalToggle = (payload: boolean) => ({
  type: CLOSE_ERROR_MODAL,
  payload,
});

export const toggleCartModal = (payload: boolean) => ({
  type: TOGGLE_CART_MODAL,
  payload,
});
