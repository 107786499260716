import { put } from "redux-saga/effects";
import {
  CLOSE_ERROR_MODAL,
  SET_CATEGORY_NAME,
  SET_PROMO_NAME,
  SET_SIGNOUT_MODAL,
  SET_UPDATE_PAYMENT_MODAL,
  SET_UPDATE_PROFILE_MODAL,
  TOGGLE_CART_MODAL,
  TOGGLE_LOGIN,
  TOGGLE_REGISTER,
} from "../../utils/constants";
import {
  setCategoryName,
  setPromoName,
  setToggleCartModal,
  setToggleErrorModal,
  setToggleLogin,
  setToggleRegister,
  setToggleSignOut,
  setToggleUpdatePayment,
  setToggleUpdateProfile,
} from "../slice/app.slice";

function* appSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case SET_CATEGORY_NAME:
      yield put(setCategoryName(payload));
      break;
    case SET_PROMO_NAME:
      yield put(setPromoName(payload));
      break;
    case TOGGLE_LOGIN:
      yield put(setToggleLogin(payload));
      break;
    case TOGGLE_REGISTER:
      yield put(setToggleRegister(payload));
      break;
    case SET_SIGNOUT_MODAL:
      yield put(setToggleSignOut(payload));
      break;
    case SET_UPDATE_PROFILE_MODAL:
      yield put(setToggleUpdateProfile(payload));
      break;
    case SET_UPDATE_PAYMENT_MODAL:
      yield put(setToggleUpdatePayment(payload));
      break;
    case CLOSE_ERROR_MODAL:
      yield put(setToggleErrorModal(payload));
      break;
    case TOGGLE_CART_MODAL:
      yield put(setToggleCartModal(payload));
      break;
    default:
      yield console.log("App default");
      break;
  }
}

export default appSaga;
