import { call, put, select, SelectEffect } from "redux-saga/effects";
import sheetService from "../../services/sheet.service";
import {
  DELETE_PENDING_TRANSACTION,
  GET_USER_WALLET_DATA,
  REQUEST_PAYOUT,
} from "../../utils/constants";
import { getUserWalletDetails } from "../actions/walletActions";
import {
  setAvailableBalance,
  setFailedOrders,
  setIsRequesting,
  setPayoutStatus,
  setRequestDone,
  setSuccessfulOrders,
  setTotalCashOut,
  setTotalOrders,
  setTotalRevenue,
  setTransaction,
} from "../slice/wallet.slice";
import { RootState, store } from "../store";

function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

const selector = (state: RootState): ReturnType<typeof store.getState> => state;

function* initRequest() {
  yield put(setIsRequesting(true));
  yield put(setPayoutStatus(null));
}

function* handleError(error?: any) {
  yield put(setPayoutStatus(null));
  const obj: Record<string, any> = {};
  obj.error = error.message || "Something went wrong";
  obj.success = false;
  obj.isError = true;

  yield put(setIsRequesting(false));
  yield put(setPayoutStatus(obj));
}

function* requestDone() {
  yield put(setIsRequesting(false));
  yield put(setPayoutStatus(null));
}

function* walletSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case GET_USER_WALLET_DATA:
      yield initRequest();
      try {
        const data: Record<string, any> = yield call(
          sheetService.getUserWalletData,
          payload
        );
        // console.log(data, "data"); //debug purposes
        if (data.success === true) {
          yield requestDone();
          yield put(setTransaction(data.data.allTransactions));
          yield put(setTotalCashOut(data.data.totalCashOut));
          yield put(setTotalOrders(data.data.totalOrders));
          yield put(setTotalRevenue(data.data.totalProfitEarned));
          yield put(setAvailableBalance(data.data.totalAvailableBalance));
          yield put(setFailedOrders(data.data.cancelledOrders));
          yield put(setSuccessfulOrders(data.data.successfulOrders));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data || error);
      }
      break;
    case DELETE_PENDING_TRANSACTION:
      yield initRequest();
      const state: ReturnType<typeof selector> = yield selectState(
        selector
      ) as unknown as ReturnType<typeof selector>;

      try {
        const user = state.auth.user;

        // delete transaction by the id
        yield call(sheetService.deletePendingTransaction, payload);

        // console.log(deleteTransaction, "returned data"); //debug purposes

        yield requestDone();
        yield put(getUserWalletDetails(user?.username));
      } catch (error: any) {
        console.log(error);
        yield handleError(error?.response.data || error);
      }
      break;
    case REQUEST_PAYOUT:
      yield initRequest();
      try {
        const data: Record<string, any> = yield call(
          sheetService.requestCashOut,
          payload
        );

        if (data?.success) {
          yield put(setIsRequesting(false));
          yield put(setRequestDone(true));
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    default:
      yield console.log("Wallet default");
      break;
  }
}

export default walletSaga;
