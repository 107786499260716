import {
  setCategory,
  setCategoryProducts,
  setExploreProducts,
  setFeedProducts,
  setFetchingProductsStatus,
  setIsCategoryProductsEmpty,
  setIsProductFetching,
  setIsPromoProductsEmpty,
  // setProductFetchError,
  setPromoProducts,
  setPromotions,
  setSearchProduct,
  setSelectedProduct,
} from "../slice/product.slice";
import {
  DOWNLOAD_PRODUCT_IMAGE,
  GET_CATEGORIES,
  GET_CATEGORY_PRODUCTS,
  GET_EXPLORE_PRODUCTS,
  GET_HOME_PRODUCTS,
  GET_PRODUCT,
  GET_PROMOTIONS,
  GET_PROMO_PRODUCTS,
  SEARCH_PRODUCTS,
  // PRODUCT_FETCH_ERROR,
  SET_PRODUCTS,
  SHARE_PRODUCT,
} from "./../../utils/constants";
import { call, put, select, SelectEffect } from "redux-saga/effects";
import sheetService from "../../services/sheet.service";
import { setTotalRecord } from "../slice/app.slice";
import { RootState, store } from "../store";
// import { RootState, store } from "../store";

// const errorStatus = {
//   status: false,
//   message: "",
// };

function* handleError(error: any) {
  const obj: Record<string, any> = {};
  obj.success = false;
  obj.error = error.message || "Something went wrong";
  obj.isError = true;
  yield put(setFetchingProductsStatus(obj));
  yield put(setIsProductFetching(false));
}

function* initRequest() {
  yield put(setFetchingProductsStatus(null));
  yield put(setIsProductFetching(true));
}

function* requestDone() {
  yield put(setIsProductFetching(false));
  yield put(setFetchingProductsStatus(null));
}

function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

const selector = (state: RootState): ReturnType<typeof store.getState> => state;

function* productSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case GET_HOME_PRODUCTS:
      yield initRequest();
      try {
        const sheet = "Evans Home";
        const data: Record<string, any> = yield call(
          sheetService.getPagedSheetData,
          sheet,
          payload
        );
        if (data.success === true) {
          // console.log(data.data);
          yield put(setFeedProducts(data.data));
          yield put(setTotalRecord(data.totalRecord));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_CATEGORIES:
      yield initRequest();
      try {
        const sheet = "prod-app-categories";
        const data: Record<string, any> = yield call(
          sheetService.getSheetData,
          sheet
        );

        if (data.success === true) {
          // console.log(data.data);
          yield put(setCategory(data.data));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_EXPLORE_PRODUCTS:
      yield initRequest();
      try {
        const sheet = "Evans Explore";
        const data: Record<string, any> = yield call(
          sheetService.getPagedSheetData,
          sheet,
          payload
        );

        if (data.success === true) {
          // console.log(data.data);
          yield put(setExploreProducts(data.data));
          yield put(setTotalRecord(data.totalRecord));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case SET_PRODUCTS:
      yield put(setSelectedProduct(payload));
      break;
    case GET_PRODUCT:
      yield initRequest();
      try {
        const sheet = "Evans Home";
        const data: Record<string, any> = yield call(
          sheetService.getSheetData,
          sheet,
          payload
        );

        if (data.success === true) {
          // console.log(data.data);
          yield put(setSelectedProduct(data.data));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_PROMOTIONS:
      yield initRequest();
      try {
        const sheet = "prod-home-promotions";
        const data: Record<string, any> = yield call(
          sheetService.getSheetData,
          sheet
        );

        if (data.success === true) {
          // console.log(data.data);
          yield put(setPromotions(data.data));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_CATEGORY_PRODUCTS:
      yield initRequest();
      const state: ReturnType<typeof selector> = yield selectState(
        selector
      ) as unknown as ReturnType<typeof selector>;
      try {
        const categoryName = state.app.categoryName;

        const data: Record<string, any> = yield call(
          sheetService.getCategory,
          categoryName,
          { page: payload.page, pageSize: payload.pageSize }
        );

        // console.log(data, "data in saga");
        if (data.success === true) {
          if (data.data.length > 0) {
            yield put(setCategoryProducts(data.data));
            yield put(setTotalRecord(data.totalRecord));
          } else {
            yield put(setIsCategoryProductsEmpty(true));
          }
          yield requestDone();
        } else {
          yield put(setIsCategoryProductsEmpty(true));
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield put(setIsPromoProductsEmpty(true));
        yield handleError(error);
      }
      break;
    case GET_PROMO_PRODUCTS:
      yield initRequest();
      try {
        const data: Record<string, any> = yield call(
          sheetService.getCategory,
          payload.promoName,
          { page: payload.page, pageSize: payload.pageSize }
        );

        if (data.success === true) {
          // console.log(data.data);
          if (data.data.length > 0) {
            yield put(setPromoProducts(data.data));
          } else {
            yield put(setIsPromoProductsEmpty(true));
          }
          yield requestDone();
        } else {
          yield put(setIsPromoProductsEmpty(true));
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);

        yield handleError(error);
      }
      break;
    case SHARE_PRODUCT:
      yield initRequest();
      try {
        const data: Record<string, any> = yield call(
          sheetService.postSharedProduct,
          payload
        );

        if (data.success) {
          yield requestDone();
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case DOWNLOAD_PRODUCT_IMAGE:
      yield initRequest();
      try {
        const data: Record<string, any> = yield call(
          sheetService.downloadMedia,
          payload
        );

        if (data) {
          yield requestDone();
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case SEARCH_PRODUCTS:
      yield initRequest();
      try {
        const sheet = "Evans Home";
        const data: Record<string, any> = yield call(
          sheetService.searchForProducts,
          sheet,
          payload
        );

        if (data.success) {
          // console.log(data.data);
          yield put(setSearchProduct(data.data));
          yield put(setTotalRecord(data.totalRecord));
          yield requestDone();
        } else {
          yield handleError(data.error);
        }
      } catch (error: any) {
        console.log(error);
        yield handleError(error);
      }
      break;

    default:
      yield console.log("Product default");
      break;
  }
}

export default productSaga;
