import React, { useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import Transition from "../Transitions";
import { useOutsideListener } from "../../hooks";
import { XIcon } from "@heroicons/react/solid";

const Modal = ({ show, setShow, size, children, canClose, height }) => {
  const ref = useRef(null);
  useOutsideListener(ref, () => {
    if (canClose) setShow(false);
  });

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const allChildren = React.Children.map(children, (child) => {
    if (child) {
      if (typeof child.type !== "function") return child;
      const clone = React.cloneElement(child, {
        // zIndex: zIndex,
        setShow: setShow,
      });

      return clone;
    }
    return null;
  });

  return (
    <Fragment>
      <Transition show={show}>
        <div className="fixed z-50 bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
          <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-50" />
            </div>
          </Transition>

          <Transition
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-20"
            enterTo="opacity-100 translate-x-0 sm:scale-100"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="opacity-100 translate-x-0 sm:scale-100"
            leaveTo="translate-x-20"
          >
            <div className="fixed z-30 bottom-0 inset-x-0 px-0 sm:inset-0 p-0 sm:flex sm:items-center sm:justify-center max-h-[580px] md:min-h-screen overflow-y-scroll">
              <div
                ref={ref}
                style={{
                  width: isTabletOrMobile ? "100%" : `${size}vw`,
                  // height: `${height}vh`,
                }}
                className="bg-white relative rounded-none px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="flex flex-col">
                  <div className="flex justify-end">
                    <span
                      className="text-sm bg-slate-200 p-1 rounded-md"
                      onClick={() => setShow(false)}
                    >
                      <XIcon className="h-4 w-4" />
                    </span>
                  </div>
                  {allChildren}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>
    </Fragment>
  );
};

Modal.defaultProps = {
  size: 35,
  canClose: true,
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  size: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  canClose: PropTypes.bool,
};

export default Modal;
