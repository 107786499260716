import { takeEvery } from "redux-saga/effects";
import {
  ADD_CUSTOMER_TO_CART,
  ADD_DELIVERY_TO_CART,
  ADD_PRODUCT_TO_CART,
  ADD_RESELLER_TO_CART,
  AUTHENTICATE_USER,
  CHECKOUT_CART,
  CLEAR_CART,
  CLOSE_ERROR_MODAL,
  DELETE_PENDING_TRANSACTION,
  DELETE_SHARED_PRODUCT,
  DELIVERY_DATA,
  DOWNLOAD_PRODUCT_IMAGE,
  EDIT_CART_ITEM,
  GET_ALL_USER_ORDERS,
  GET_CATEGORIES,
  GET_CATEGORY_PRODUCTS,
  GET_COUPON,
  GET_CUSTOMERS,
  GET_EXPLORE_PRODUCTS,
  GET_HOME_PRODUCTS,
  GET_ORDER_DETAILS,
  GET_PRODUCT,
  GET_PROMOTIONS,
  GET_PROMO_PRODUCTS,
  GET_USER_SHARED_PRODUCTS,
  GET_USER_WALLET_DATA,
  LOGIN_USER,
  LOGOUT,
  ORDERING,
  ORDER_PRODUCT,
  REGISTER_USER,
  REMOVE_PRODUCT_FROM_CART,
  REQUEST_PAYOUT,
  SEARCH_PRODUCTS,
  SET_CATEGORY_NAME,
  SET_PRODUCTS,
  SET_PROMO_NAME,
  SET_SIGNOUT_MODAL,
  SHARE_PRODUCT,
  TOGGLE_CART_MODAL,
  TOGGLE_LOGIN,
  TOGGLE_REGISTER,
  UPDATE_USER_PAYMENT,
  UPDATE_USER_PROFILE,
} from "../../utils/constants";
import appSaga from "./appSaga";
import authSaga from "./authSaga";
import cartSaga from "./cartSaga";
import orderSaga from "./orderSaga";
import productSaga from "./productSaga";
import walletSaga from "./walletSaga";

function* rootSaga() {
  yield takeEvery(
    [
      SET_CATEGORY_NAME,
      SET_PROMO_NAME,
      TOGGLE_LOGIN,
      TOGGLE_REGISTER,
      SET_SIGNOUT_MODAL,
      CLOSE_ERROR_MODAL,
      TOGGLE_CART_MODAL,
    ],
    appSaga
  );
  yield takeEvery(
    [
      LOGIN_USER,
      REGISTER_USER,
      AUTHENTICATE_USER,
      LOGOUT,
      UPDATE_USER_PAYMENT,
      UPDATE_USER_PROFILE,
    ],
    authSaga
  );
  yield takeEvery(
    [
      GET_HOME_PRODUCTS,
      GET_CATEGORIES,
      GET_EXPLORE_PRODUCTS,
      GET_PROMOTIONS,
      SET_PRODUCTS,
      GET_PRODUCT,
      GET_CATEGORY_PRODUCTS,
      GET_PROMO_PRODUCTS,
      SHARE_PRODUCT,
      DOWNLOAD_PRODUCT_IMAGE,
      SEARCH_PRODUCTS,
    ],
    productSaga
  );
  yield takeEvery(
    [
      ORDER_PRODUCT,
      DELIVERY_DATA,
      ORDERING,
      GET_ALL_USER_ORDERS,
      GET_ORDER_DETAILS,
      GET_USER_SHARED_PRODUCTS,
      DELETE_SHARED_PRODUCT,
    ],
    orderSaga
  );
  yield takeEvery(
    [GET_USER_WALLET_DATA, DELETE_PENDING_TRANSACTION, REQUEST_PAYOUT],
    walletSaga
  );

  yield takeEvery(
    [
      ADD_PRODUCT_TO_CART,
      REMOVE_PRODUCT_FROM_CART,
      ADD_CUSTOMER_TO_CART,
      ADD_RESELLER_TO_CART,
      EDIT_CART_ITEM,
      CLEAR_CART,
      ADD_DELIVERY_TO_CART,
      GET_CUSTOMERS,
      GET_COUPON,
      CHECKOUT_CART,
    ],
    cartSaga
  );
}

export default rootSaga;
