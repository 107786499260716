import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  isFetching: boolean;
  promoName: string;
  categoryName: string;
  productName: string;
  toggleLogin: boolean;
  toggleRegister: boolean;
  toggleSignOut: boolean;
  toggleUpdateProfile: boolean;
  toggleUpdatePayment: boolean;
  toggleErrorModal: boolean;
  totalRecord: number;
  toggleCartModal: boolean;
  previousPage?: string;
}

const initialState: AppState = {
  isFetching: false,
  promoName: "",
  categoryName: "",
  productName: "",
  toggleLogin: false,
  toggleRegister: false,
  toggleSignOut: false,
  toggleUpdateProfile: false,
  toggleUpdatePayment: false,
  toggleErrorModal: false,
  totalRecord: 1,
  toggleCartModal: false,
  previousPage: undefined,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setPromoName: (state, action: PayloadAction<string>) => {
      state.promoName = action.payload;
    },
    setCategoryName: (state, action: PayloadAction<string>) => {
      state.categoryName = action.payload;
    },
    setProductName: (state, action: PayloadAction<string>) => {
      state.productName = action.payload;
    },
    setToggleLogin: (state, action: PayloadAction<boolean>) => {
      state.toggleLogin = action.payload;
    },
    setToggleRegister: (state, action: PayloadAction<boolean>) => {
      state.toggleRegister = action.payload;
    },
    setToggleSignOut: (state, action: PayloadAction<boolean>) => {
      state.toggleSignOut = action.payload;
    },
    setToggleUpdateProfile: (state, action: PayloadAction<boolean>) => {
      state.toggleUpdateProfile = action.payload;
    },
    setToggleUpdatePayment: (state, action: PayloadAction<boolean>) => {
      state.toggleUpdatePayment = action.payload;
    },
    setToggleErrorModal: (state, action: PayloadAction<boolean>) => {
      state.toggleErrorModal = action.payload;
    },
    setTotalRecord: (state, action: PayloadAction<number>) => {
      state.totalRecord = action.payload;
    },
    setToggleCartModal: (state, action: PayloadAction<boolean>) => {
      state.toggleCartModal = action.payload;
    },
    setPreviousPage: (state, action: PayloadAction<string>) => {
      state.previousPage = action.payload;
    },
  },
});

export const {
  setIsFetching,
  setPromoName,
  setCategoryName,
  setProductName,
  setToggleLogin,
  setToggleRegister,
  setToggleSignOut,
  setToggleUpdateProfile,
  setToggleUpdatePayment,
  setToggleErrorModal,
  setTotalRecord,
  setToggleCartModal,
  setPreviousPage,
} = appSlice.actions;

export default appSlice.reducer;
