import { lazy } from "react";

export const formatPhoneNumber = ({
  value,
  cb,
  errorCb,
}: {
  value: string;
  cb: (value: string) => void;
  errorCb: (value: boolean) => void;
}) => {
  const countryCode = value.substring(0, 3);
  const phone = value.substring(3);

  if (countryCode === "233" && phone.length === 9) {
    // console.log(value, "actual number"); // for debugging
    errorCb(false);
    cb(value);
  } else {
    if (phone.length === 10) {
      const newPhone = phone.substring(1);
      // console.log(newPhone, "<<< new phone number"); // for debugging
      errorCb(false);
      cb(`233${newPhone}`);
    } else if (phone.length > 10) {
      errorCb(true);
    }
  }
};

export const formatNigeriaPhoneNumber = ({
  phone,
  cb,
  errorCb,
}: {
  phone: string;
  cb: (value: string) => void;
  errorCb: (value: boolean) => void;
}) => {
  const countryCode = phone.substring(0, 3);
  const value = phone.substring(3);

  if (countryCode === "234" && value.length === 10) {
    // console.log(phone, "actual number"); // for debugging
    errorCb(false);
    cb(phone);
  } else {
    if (value.length === 11) {
      const newPhone = value.substring(1);
      //console.log(newPhone, "<<< new phone number"); // for debugging
      errorCb(false);
      cb(`${countryCode}${newPhone}`);
    } else if (value.length < 10 || value.length > 11) {
      errorCb(true);
    }
  }
};

export const formatDateOfBirth = (value: string) => {
  return value.replace(/\D/g, "/").replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
};

export const createUserName = ({
  name,
  phone,
  length,
}: {
  name: string;
  phone: string;
  length: number;
}) => {
  let username;
  const part1 = name.split(" ")[0].substring(0, length);
  const part2 = phone.substring(phone.length - length);
  username =
    `${part1}${part2}${new Date().getDay()}${new Date().getHours()}${new Date().getMinutes()}`.toUpperCase();
  return username;
};

export const generateOrderNumber = () => {
  return `RS${Math.floor(Math.random() * 900000000000000) + 100000000000000}`;
};

export const generateTrxnNumber = () => {
  return `TS${Math.floor(Math.random() * 900000000000000) + 100000000000000}`;
};

export const sentSlackMsg = async (webHook: string, msg: any) => {
  fetch(webHook, {
    method: "POST",
    credentials: "omit",
    body: JSON.stringify(msg),
  })
    .then((res) => res.json())
    .then((res) => console.log(res))
    .catch((e) => console.log(e));
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const img: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  img.lastModifiedDate = new Date();
  img.name = fileName;

  //Cast to a File() type
  return theBlob as File;
};

export const getTime = (date?: any) => {
  if (!date) return 0;
  const d = new Date(convertDateToUS(date));
  if (d) {
    return d.getTime();
  } else {
    return 0;
  }
};

export const convertDateToUS = (date: string) => {
  return date.split("/").reverse().join("-");
};

export function isSafari() {
  if (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1
  )
    return true;
  return false;
}

export const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const processArrayWithDelay = (
  array: any[],
  callback: (el: any) => void,
  doneIndicatorFn?: any,
  delay = 1
) => {
  let promise = Promise.resolve();
  array.forEach((el) => {
    promise = promise.then(() => {
      callback(el);
      return new Promise((resolve) => {
        setTimeout(resolve, delay * 1000);
      });
    });
  });

  promise.then(function () {
    doneIndicatorFn && doneIndicatorFn();
    console.log("process done.");
  });
};
