import { call, put, select, SelectEffect } from "redux-saga/effects";
import sheetService from "../../services/sheet.service";
import {
  DELETE_SHARED_PRODUCT,
  DELIVERY_DATA,
  GET_ALL_USER_ORDERS,
  GET_ORDER_DETAILS,
  GET_USER_SHARED_PRODUCTS,
  ORDERING,
  ORDER_PRODUCT,
} from "../../utils/constants";
import { setToggleErrorModal, setTotalRecord } from "../slice/app.slice";
import {
  setDeliveryLocations,
  setIsOrdering,
  setNavigate,
  setOrderingStatus,
  setOrderProduct,
  setOrders,
  setProductOrdered,
  setSharedProducts,
} from "../slice/order.slice";
import { RootState, store } from "../store";

function* handleError(error?: any) {
  const obj: Record<string, any> = {};
  obj.error = error.message || "Something went wrong";
  obj.success = false;
  obj.isError = true;

  yield put(setOrderingStatus(obj));
  yield put(setIsOrdering(false));
}

function* initRequest() {
  yield put(setIsOrdering(true));
  yield put(setOrderingStatus(null));
}

function* requestDone() {
  yield put(setIsOrdering(false));
  yield put(setOrderingStatus(null));
}

function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

const selector = (state: RootState): ReturnType<typeof store.getState> => state;

function* orderSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case ORDER_PRODUCT:
      yield put(setOrderProduct(payload));
      break;
    case DELIVERY_DATA:
      const sheet = "prod-delivery-prices";

      try {
        yield initRequest();
        const data: Record<string, any> = yield call(
          sheetService.getSheetData,
          sheet
        );

        if (data.success === true) {
          yield put(setDeliveryLocations(data.data));
          yield requestDone();
        } else {
          yield handleError({ message: data.data });
          yield put(setToggleErrorModal(true));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case ORDERING:
      try {
        yield initRequest();

        const order: Record<string, any> = yield call(
          sheetService.createOrder,
          payload
        );

        // console.log(order, "order in saga");

        if (order?.success) {
          yield put(setNavigate(true));
          yield requestDone();
        } else {
          yield handleError({ message: order.error });
          yield put(setToggleErrorModal(true));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_ALL_USER_ORDERS:
      yield initRequest();
      try {
        const orders: Record<string, any> = yield call(
          sheetService.getOrderData,
          payload
        );

        if (orders.success === true) {
          yield put(setOrders(orders.data));
          yield put(setTotalRecord(orders.totalRecord));
          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_ORDER_DETAILS:
      try {
        yield initRequest();
        const order: Record<string, any> = yield call(
          sheetService.getOrderDetails,
          payload
        );
        // console.log(order);

        if (order.success === true) {
          yield put(setProductOrdered(order.data));
          yield requestDone();
        } else {
          yield handleError({ message: order.error });
          yield put(setToggleErrorModal(true));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_USER_SHARED_PRODUCTS:
      yield initRequest();
      try {
        const sheet = "Shared Products";
        const products: Record<string, any> = yield call(
          sheetService.getPagedSheetData,
          sheet,
          payload
        );
        // console.log(products);

        if (products.success === true) {
          yield put(
            setSharedProducts(
              products.data.sort((a: { id: number }, b: { id: number }) =>
                b.id > a.id ? 1 : -1
              )
            )
          );
          yield put(setTotalRecord(products.totalRecord));
          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case DELETE_SHARED_PRODUCT:
      yield initRequest();

      const state: ReturnType<typeof selector> = yield selectState(
        selector
      ) as unknown as ReturnType<typeof selector>;

      const stateSharedProducts = state.orders.sharedProducts;

      try {
        const res: Record<string, any> = yield call(
          sheetService.deleteSharedProduct,
          payload
        );

        if (res.success) {
          const sharedProducts = stateSharedProducts;
          const filteredProducts = sharedProducts.filter(
            (x: Record<string, any>) => x.id !== payload
          );
          // console.log(filteredProducts, "filter in saga");
          yield put(setSharedProducts(filteredProducts));

          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    default:
      yield console.log("Order default");
      break;
  }
}

export default orderSaga;
