import { useEffect, useRef, useState } from "react";
import useDidMount from "./useDidMount";

type IScriptResult = {
  loaded: boolean;
  error: boolean;
};

export function useFreshDeskScript(cb: Function): boolean[] {
  const src = "https://wchat.freshchat.com/js/widget.js";
  const didMount = useDidMount();

  const [state, setState] = useState<IScriptResult>({
    loaded: false,
    error: false,
  });
  const init = useRef({
    cb,
  });

  useEffect(() => {
    const { cb } = init.current;

    if (didMount) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.defer = true;
      script.id = "freshchat-js-script";

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });

        cb();
      };

      const onScriptError = () => {
        script.remove();
        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);
      script.addEventListener("complete", onScriptLoad);

      document.head.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
  }, [didMount, src]);

  const { error, loaded } = state;

  return [loaded, error];
}
