import {
  DELETE_PENDING_TRANSACTION,
  GET_USER_WALLET_DATA,
  REQUEST_PAYOUT,
} from "../../utils/constants";

export const getUserWalletDetails = (payload: string) => ({
  type: GET_USER_WALLET_DATA,
  payload,
});

export const deleteUserPendingTrxn = (payload: Record<string, any>) => ({
  type: DELETE_PENDING_TRANSACTION,
  payload,
});

export const payoutProfit = (payload: Record<string, any>) => ({
  type: REQUEST_PAYOUT,
  payload,
});
