import { ICart, IOrder, IUser } from "../utils/types";
import { NetworkService } from "./network.service";

class SheetService {
  private baseUrl: string;
  private network: any;
  constructor() {
    this.baseUrl =
      process.env.NODE_ENV === "production"
        ? "https://ghana.main.tendoservices.dev/api/v1/"
        : "https://ghana.main.tendoservices.dev/api/v1/";
    this.network = new NetworkService(this.baseUrl);
  }

  public getSheetData = async (sheet: string, params?: Record<string, any>) => {
    const url = `catalog/${sheet}`;

    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getPagedSheetData = async (
    sheet: string,
    params?: Record<string, any>
  ) => {
    const url = `catalog/paged/${sheet}`;
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getCategory = async (
    category: string,
    params?: Record<string, any>
  ) => {
    const url = `catalog/categories/${category}`;
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getUserWalletData = async (username: string) => {
    const url = `orders/wallet/${username}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public downloadMedia = async (media: string) => {
    const data = { url: media };
    const url = `/sheet/media`;
    const response = await this.network.post(url, data);
    return response.data;
  };

  public searchForProducts = async (sheet: string, q: string) => {
    const url = `catalog/search/${sheet}`;
    const params = { q };
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public createAccount = async (user: IUser) => {
    const url = `users/create`;
    const data = { user };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getUser = async (param: string, key: string) => {
    const url = `users?filter[${key}]=${param}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public login = async (phone: Record<string, any>) => {
    const url = `users/login`;
    const data = phone;
    const response = await this.network.post(url, data);
    return response.data;
  };

  public updateUser = async (user: IUser) => {
    const url = `users/update`;
    const data = { user };
    const response = await this.network.put(url, data);
    return response.data;
  };

  public createOrder = async (order: IOrder) => {
    const url = `orders/create`;
    const data = {
      order: {
        ...order,
        customerSupportNumber: 233503247275,
        customerSupportName: "Precious",
      },
    };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public updateOrder = async (order: IOrder) => {
    const url = `orders/update`;
    const data = {
      order,
    };
    const response = await this.network.put(url, data);
    return response.data;
  };

  public getOrderData = async (params: Record<string, any>) => {
    const url = `orders`;
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public postSharedProduct = async (product: Record<string, any>) => {
    const url = `catalog/share`;
    const data = { product };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getOrderDetails = async (orderNumber: string) => {
    const url = `orders/details/${orderNumber}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public deletePendingTransaction = async (transactionId: any) => {
    const url = `orders/transactions/${transactionId}`;
    const response = await this.network.delete(url);
    return response.data;
  };

  public requestCashOut = async (payout: Record<string, any>) => {
    const url = `orders/transactions/request`;
    const data = { resellerProfitRequest: payout };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public deleteSharedProduct = async (id: number) => {
    const url = `catalog/share/delete/${id}`;
    const response = await this.network.delete(url);
    return response.data;
  };

  public checkoutCart = async (cart: ICart) => {
    const url = `orders/cart/checkout`;
    const data = { cart };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getUserCustomers = async (username: string) => {
    const url = `users/customers/${username}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public getCouponCode = async (code: string, user: string) => {
    const url = `orders/cart/coupon/claim/${code}`;
    const params = {
      user,
    };
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public updateOrdersWithNewPhone = async (args: {
    oldPhone: string;
    newPhone: string;
  }) => {
    const url = "/user/orders/update";
    const data = args;
    const response = await this.network.post(url, data);

    return response.data;
  };
}

const sheetService = new SheetService();
export default sheetService;
